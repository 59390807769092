<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 협력사 협의체 기본정보 -->
      <c-card title="LBL0003096" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="tabParam.councilTitle" class="col-6">
            <!-- 제목 -->
            <c-label-text title="LBLTITLE" :value="tabParam.councilTitle"></c-label-text>
          </div>
          <div v-if="tabParam.councilDate" class="col-6">
            <!-- 일자 -->
            <c-label-text title="LBL0003064" :value="tabParam.councilDate"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 즉시조치 -->
      <c-table
        ref="grid1"
        title="LBL0003077"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="tabParam.imprImmData"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="immlinkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 개선요청 -->
      <c-table
        ref="grid2"
        class="q-mt-md"
        title="LBLIMPRREQUEST"
        :columns="grid2.columns"
        :gridHeight="grid2.height"
        :data="tabParam.imprData"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="imprlinkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
export default {
  name: 'council-impr',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        councilDate: '',
        councilContents: '',
        councilTitle: '',
        councilId: '',
        imprData: [],
        imprImmData: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0003078',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            // 조치내용
            label: 'LBL0003079',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치완료일
            label: 'LBL0003080',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0003078',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            // 요청내용
            label: 'LBL0003081',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료 요청일
            label: 'LBL0003082',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
    };
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 260) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    addImprImm() {
      this.popupOptions.title = 'LBLIMMEDIATE'; // 즉시조치
      this.popupOptions.param = {
        requestContents: this.tabParam.councilContents,
        relationTableKey: this.tabParam.councilId,
        ibmTaskTypeCd: 'ITT0000080',
        ibmTaskUnderTypeCd: 'ITTU000135',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: this.tabParam.councilContents,
        relationTableKey: this.tabParam.councilId,
        ibmTaskTypeCd: 'ITT0000080',
        ibmTaskUnderTypeCd: 'ITTU000135',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail')
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBL0003083'; // 즉시조치 상세
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail')
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['grid2'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail')
    },
  }
};
</script>
